import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, watch, onMounted } from 'vue';
import { pointsStore } from "../stores/PointsStore.js";


export const measureToolStore = defineStore('measuretool', () => {


	const points = pointsStore();

	const measureMode = ref(false);
	const measureGeojson = ref({'type': 'FeatureCollection', 'features': []});
	const measureLinestring = ref({
				'type': 'Feature',
				'geometry': {
					'type': 'LineString',
					'coordinates': []
				}});
	const measureDistance = ref(null);

	const targetMode = ref(false);
	const targetGeojson = ref({'type': 'FeatureCollection', 'features': []});

	const redraw = ref(0);
	const targetMoved = ref(0);

	const targetCoordinates = function() {
		if (targetGeojson.value.features.length==0) return null;
		if (targetGeojson.value.features[0]?.geometry?.length==0) return null;
		//console.log(targetGeojson.value.features[0].geometry.coordinates);
		return targetGeojson.value.features[0].geometry.coordinates;
	}

	const hasTarget = function() {
		if (targetGeojson.value.features.length>0) return true;
		return false;
	}
	const clearTarget = function() {
		targetGeojson.value.features = [];
	}


	// check for moved points 
	watch(() => points.pointsChanged, (currentValue, oldValue) => {

		//console.log('noted pointsChanged');
		// target tool --------------
		if (targetGeojson.value.features.length>0)
		{
			if (targetGeojson.value.features[0].properties?.key) {
				let point = points.getKey(targetGeojson.value.features[0].properties?.key);
				if (point && point.long) {
					targetGeojson.value.features[0].geometry.coordinates = [point.long, point.lat];
					targetMoved.value++;
				}
			}
		}

		// measure distance tool --------------
		if (measureDistance.value!=null)
		{
			// console.log('STUFF:');
			// console.log(measureLinestring.value.geometry.coordinates);
			// console.log(measureGeojson.value.features);
			//console.log('measureDistance.value != null');
			for (var i=0; i<measureGeojson.value.features.length; i++) {
				//console.log(measureGeojson.value.features[i]);

				if (measureGeojson.value.features[i].properties?.key) {
					let point = points.getKey(measureGeojson.value.features[i].properties?.key);

					if (!point) continue;
					if (!point.long) continue;

					// update point positions
					measureGeojson.value.features[i].geometry.coordinates = [point.long, point.lat];
					let lastItem = measureGeojson.value.features.length;
					measureGeojson.value.features[lastItem-1].geometry.coordinates[i] = [point.long, point.lat];

					// console.log('measureLinestring.value.geometry.coordinates[lastItem-1]');
					// console.log(measureLinestring.value.geometry.coordinates[lastItem-1]);
					measureLinestring.value.geometry.coordinates[lastItem-1] = [point.long, point.lat];
				}
			}
			redraw.value++;
		}
		
	});





	return {
		measureMode,
		measureGeojson,
		measureLinestring,
		measureDistance,
		targetMode,
		targetGeojson,
		redraw,
		targetMoved,
		targetCoordinates,
		hasTarget,
		clearTarget,
	}

},
{
	persist: {
		key: 'measureTools',
		// afterRestore: (ctx) => {
		// 	//console.log(`just restored '${ctx.store.$id}'`)
		// }
	},
},);


// make sure to pass the right store definition
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(measureToolStore, import.meta.hot))
}

