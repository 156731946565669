import { watch, ref } from 'vue';

import { settingsStore } from "../stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { useMapTools } from "@/Composables/MapTools.js";

import { renderDistance, roundDecimals } from "@/Helpers.js";
import { point, lineString } from '@turf/helpers';
import bearing from '@turf/bearing';
import destination from '@turf/destination';
import circle from '@turf/circle';
import sector from '@turf/sector';
import union from '@turf/union';
import distance from '@turf/distance';
import length from '@turf/length';


var firstLoad = ref(true);

//import { useI18n } from 'vue-i18n'



/**
 * 
 * General task drawer
 * 
 */


/*

Style (direction)
Observation Zone Direction
0 - Fixed value
1 - Symmetrical
2 - To next point
3 - To previous point
4 - To start point
*/

export function useDrawCompTasks() {

	//const { t } = useI18n({ useScope: "global" });
	const settings = settingsStore();
	const app = appStateStore();
	const mapTools = useMapTools();



	function showTasks() {
		// turn all of them on
		for (var i=0; i<app.tasks?.length; i++) {

			// find the class that matches
			app.tasks[i].show=false;
			let compClass = app.comp.classes.filter(obj => {
				// console.log(obj);
				return obj.id === app.tasks[i].class_id
			});
			if (compClass && compClass[0].showTask) {
				//console.log('Showing Class ' + compClass.id);
				app.tasks[i].show=true;
			}
			app.tasks[i].loaded=true;
		}

		redrawTasks(app.tasks, firstLoad.value);

		if (firstLoad.value) {
			firstLoad.value=false;
		} else {
			app.restartLoop=true;
		}
		
	}

	function redrawTasks(tasks, zoom=false) {

		// console.log("drawing tasks");
		// console.log(tasks);

		// default dark colours
		var colours = [
			'#093145',
			'#107896',
			'#829356',
			'#9A2617',
			'#C25714',
			'#BCA136',
			];
		var coloursLight = [
			'#3C6478',
			'#43ABC9',
			'#B5C689',
			'#EFD469',
			'#F58B4C',
			'#CD594A',
			];

		if (app.isMapDark()) {
			colours = coloursLight;
		}

		for (var i=0; i<tasks.length; i++) {

			// add the colour to the list of tasks
			//console.log(colours.length + ' ' + i + ' ' +  i % colours.length);
			
			tasks[i].colour = colours[i % colours.length];
			//console.log(tasks[i].colour);

			removeTaskFromMap(tasks[i]);
			if (tasks[i].active || tasks[i].show) renderTask(tasks[i]);

			tasks[i].coordinates = [];

			for (var j=0; j<tasks[i].turnpoints.length; j++)
			{
				tasks[i].coordinates.push([
					tasks[i].turnpoints[j].turnpoint.long,
					tasks[i].turnpoints[j].turnpoint.lat,
				]);
			}
		}
		if (zoom==true && tasks[0]?.coordinates.length>0) zoomToTasks(tasks);
	}

	function removeTasks(tasks) {
		if (!tasks) return;
		for (var i=0; i<tasks.length; i++) {
			removeTaskFromMap(tasks[i]);
		}
	}

	function removeTaskFromMap(task) {
		//console.log('removing task ' + task.id + ' from map');
		if (app.map.getLayer('directions-' + task.id)) {
			app.map.removeLayer('directions-' + task.id);
		}
		if (app.map.getLayer('layer-' + task.id)) {
			app.map.removeLayer('layer-' + task.id);
		}
		if (app.map.getLayer('line-labels-' + task.id)) {
			app.map.removeLayer('line-labels-' + task.id);
		}
		if (app.map.getSource('source-' + task.id)) {
			app.map.removeSource('source-' + task.id);
		}
		if (app.map.getLayer('tp-layer-' + task.id)) {
			app.map.removeLayer('tp-layer-' + task.id);
		}
		if (app.map.getLayer('tp-layer-fill-' + task.id)) {
			app.map.removeLayer('tp-layer-fill-' + task.id);
		}
		if (app.map.getSource('tp-source-' + task.id)) {
			app.map.removeSource('tp-source-' + task.id);
		}
		if (app.map.hasImage('direction-w')) app.map.removeImage('direction-w');
		if (app.map.hasImage('direction-d')) app.map.removeImage('direction-d');
	}


	function zoomToTasks(tasks) {
		let coords = [];
		for (var i=0; i<tasks.length; i++) {
			if (tasks[i].show && tasks[i].coordinates) {
				coords = [...coords, ...tasks[i].coordinates];
			}
		}

		if (coords.length==0) return;

		var bounds = coords.reduce(function(bounds, coord) {
			return bounds.extend(coord);
		}, new app.mapbox.LngLatBounds(coords[0], coords[1]));

		app.map.fitBounds(bounds, {
			padding: 70
		});
	}



	function renderTask(task) {
		// console.log('rendering task');
		// console.log(task);


		if (!task.turnpoints) return;

		// check we haven't already loaded it
		if (app.map.getLayer('layer-' + task.id)) {
			return;
		}

		if (!task) return;

		//console.log('got here 1');
		// console.log(task);

		//this.clearCurrentTask();
		var coordinates = [];
		var linesFeatures = [];

		// sort the turnpoints to make sure they're correct
		task.turnpoints = task.turnpoints.sort((a, b) => a.order - b.order);

		// get total distance
		var linestring = [];
		for (var i=0; i<task.turnpoints.length; i++) {
			var p = task.turnpoints[i];
			linestring.push([p.turnpoint.long, p.turnpoint.lat]);
		}
		var line = lineString(linestring);
		var totalLength = length(line, { units: "kilometers" }) * 1000;


		for (var i=0; i<task.turnpoints.length; i++) {
			var p = task.turnpoints[i];
			// only create a line IF this is not a multi-start point
			if (p.multiple_start==undefined) p.multiple_start=false;
			if (p.multiple_start==false) {
				coordinates.push([p.turnpoint.long, p.turnpoint.lat]);
			}

			if (i>0) {
				var o = task.turnpoints[i-1];

				let d = distance(
					point([p.turnpoint.long, p.turnpoint.lat]),
					point([o.turnpoint.long, o.turnpoint.lat]),
					{ units: "kilometers" }
				);

				let dist = roundDecimals(d*1000, 0);
				let percent = Math.round((dist / totalLength)*100);

				let taskName = '';
				if (task.compclass?.name) {
					taskName = task.compclass.name + '     ' ;
				} else if (task.name) {
					if (task.name.length>14) {
						taskName = task.name.substring(0, 12) + '...     ' ;
					} else {
						taskName = task.name + '     ' ;
					}
					
				}

				linesFeatures.push ({
					"type": "Feature",
					"geometry": {
						"type": "LineString",
						"coordinates": [
						[o.turnpoint.long, o.turnpoint.lat],
						[p.turnpoint.long, p.turnpoint.lat]
						]
					},
					"properties": {
						"title":  taskName +  'Leg ' + i + 
							'     ' + renderDistance(dist, settings.distanceUnits) + 
							' of ' + renderDistance(totalLength, settings.distanceUnits) + 
							'     ' + percent + '%'
					}
				});
			}
		}
		//console.log('got here 2');
		//console.log(coordinates);

		// create turnpoints
		var turnpoints = [];
		for (var i=0; i<task.turnpoints.length; i++) 
		{

			// reset calculations for sectors
			bearing1 = null;
			bearing2 = null;

			// console.log(task.task.task_points);
			var t = task.turnpoints[i];

			// var center = [-75.343, 39.984];
			// var radius = 5;
			var options = {steps: 40, units: 'meters'};

			// setting default direction
			if (t.type=='start' && t.direction===null) {
				// console.log('setting default direction');
				t.direction = 2;
			}


			if (t.r1==null) {
				t.r1=500; // set a default if no radius provided
			}

			// check if this is a line (or a start semi-circle which we will draw as a line)
			if (t.line==1 || (t.type=='start' && t.a1!=180)) {

		//console.log('got here 2.1');
				// calc bearing to the next point
				var centerPoint = point([t.turnpoint.long, t.turnpoint.lat]);
		//console.log('got here 2.11');
				// check if this is a start or finish line
				if (t.type=='start') {
					// console.log('start');
					// it's a start line. Use next point
					if (!task.turnpoints[i+1]) continue;
					var point2 = point([task.turnpoints[i+1].turnpoint.long, task.turnpoints[i+1].turnpoint.lat]);
				}
				if (t.type=='finish') {
					// console.log('finish');
					// console.log(task.turnpoints);
					// must be the end point. Use previous point.
					var point2 = point([task.turnpoints[i-1].turnpoint.long, task.turnpoints[i-1].turnpoint.lat]);
				}
		//console.log('got here 2.3');
				var brng = bearing(centerPoint, point2);

				// console.log('bearing to next/previous point is: ' + brng);

				// create end points of the line
				var dist = t.r1;
				var options = {steps: 60, units: 'meters'};

				// check if we're going to go over 180, if so use negative instead
				if (brng < 0) {
					var bearing1 = brng + 90;
					var bearing2 = brng + 270;
				} else {
					var bearing1 = brng - 90;
					var bearing2 = brng - 270;
				} 
		//console.log('got here 2.4');

				// console.log('bearing 1: ' + bearing1 + ' bearing2 ' + bearing2);

				// create a destination point for each end of the line
				var destination1 = destination(centerPoint, dist, bearing1, options);
				var destination2 = destination(centerPoint, dist, bearing2, options);

		//console.log('got here 2.5');
				// console.log('end of line point 1:');
				// console.log(destination1);
				// console.log('end of line point 2:');
				// console.log(destination2);

				turnpoints.push(lineString([destination1.geometry.coordinates, destination2.geometry.coordinates]));

		//console.log('got here 3 made line');

			} else {

				// check if we are drawing a circle or a sector
				if (t.a1==180)
				{
		//console.log('got here 8');
					// draw circle
					turnpoints.push(circle([t.turnpoint.long, t.turnpoint.lat], t.r1, options));
					//console.log( JSON.stringify(circle([t.turnpoint.long, t.turnpoint.lat], t.r1, options)));

				} else {
					// it's a sector.

		//console.log('got here 9');
					var radius = t.r1;
					var centerPoint = point([t.turnpoint.long, t.turnpoint.lat]);
					var options = {steps: 100, units: 'meters'};

					// do we have a fixed angle or not?
					// console.log('direction ');
					// console.log(t);
					switch (t.direction) {
						case 0: // fixed
		//console.log('got here 10');
							// console.log("fixed")
							// figure out bearing to the two sides of the segment
							// a12 is between 0 and 360. We need bearing between -180 and 180
							var centerBearing = Math.round(t.a12 - 180);
							var bearing1 = t.a12 - t.a1 - 180; // one edge of segment
							var bearing2 = t.a12 + t.a1 - 180 + 0.5; // other edge of segment
							break;

						case 1: //symmetric
		//console.log('got here 11');
							// console.log("symmetric");
							// console.log(task)
							if (!task.turnpoints[i+1]) continue;
							var nextPoint = point([task.turnpoints[i+1].turnpoint.long, task.turnpoints[i+1].turnpoint.lat]);
							var previousPoint = point([task.turnpoints[i-1].turnpoint.long, task.turnpoints[i-1].turnpoint.lat]);

		//console.log('got here 11.1');
							var bearingNextPoint = bearing(centerPoint, nextPoint);
							var bearingPreviousPoint = bearing(centerPoint, previousPoint);

							// console.log("a1: " + (t.a1));
							// console.log("a12: " + (t.a12));
							// console.log("bearingNextPoint: " + (bearingNextPoint));
							// console.log("bearingPreviousPoint: " + (bearingPreviousPoint));

		//console.log('got here 11.2');
							// work out the gap between, and the bearing between them
							var gapBetween = bearingNextPoint-bearingPreviousPoint;
							var bearingBetween = bearingNextPoint - gapBetween/2;

							if (gapBetween<180 && gapBetween>-180) bearingBetween = bearingBetween+180;

		//console.log('got here 11.3');
							// console.log("gapBetween: " + gapBetween);
							// console.log("bearingBetween: " + bearingBetween);

							// add on the size of the sector either side of it
							var bearing1 = bearingBetween - (t.a1); // one edge of segment
							var bearing2 = bearingBetween + (t.a1+1) ; // other edge of segment

							break;

						default: 
		//console.log('got here 12');
							turnpoints.push(circle([t.turnpoint.long, t.turnpoint.lat], t.r1, options));
							//console.log(circle([t.turnpoint.long, t.turnpoint.lat], t.r1, options));
							break;
					}

					// console.log('bearing1');
					// console.log(bearing1);
					// console.log('bearing2');
					// console.log(bearing2);

					if (bearing1 && bearing2) {
		//console.log('got here 13');
						var sect = sector(centerPoint, radius, bearing1, bearing2, options);
					}
					

					// add the second circle sector if included
					if (t.r2>0) {

						var circle2 = circle([t.turnpoint.long, t.turnpoint.lat], t.r2, options);
						var unn = union(sect, circle2);
						turnpoints.push(unn);
						//console.log(JSON.stringify(unn));
					} else {
						turnpoints.push(sect);
						//console.log(JSON.stringify(sect));
					}

		//console.log('got here 14');
		//console.log(turnpoints[turnpoints.length-1]);
					
				}

			}

		//console.log('got here 15');
		//console.log('got here 5');

// course_in: 228
// course_out: 267
// distance: 136865.24790278
// elevation: 137.4648
// latitude: -36.66278333333342
// longitude: 142.3733333333356
// multiple_start: false
// name: "09ControlPointEast"
// a1: 180
// oz_angle2: 0
// a12: 338
// line: false
// oz_max_altitude: 0
// oz_move: false
// oz_radius1: 2000
// oz_radius2: 0
// oz_reduce: false
// oz_type: "symmetric"
// point_index: 3
// type: "point"
// Object Prototype

			// turnpoints.push({
			// 		'type': 'Feature',
			// 		'geometry': {
			// 			'type': 'Polygon',
			// 			'coordinates': circle
			// 		}
			// 	});
		}

		// console.log('turnpoints');
		// console.log(turnpoints);
		// console.log('linesFeatures');
		// console.log(linesFeatures);


		var colour = '#000';
		if (task.colour) colour = task.colour;

		app.map.addSource('tp-source-' + task.id, {
			'type': 'geojson',
			'data': {
				'type': 'FeatureCollection',
				'features': turnpoints,
			}
		});
		app.map.addLayer({
			'id': 'tp-layer-' + task.id,
			'type': 'fill',
			'source': 'tp-source-' + task.id,
			'layout': {
			},
			'paint': {
				'fill-color': colour, // blue color fill
				'fill-opacity': 0.1
			}
		}, 'tasks-slot');
		app.map.addLayer({
			'id': 'tp-layer-fill-' + task.id,
			'type': 'line',
			'source': 'tp-source-' + task.id,
			'layout': {
				'line-join': 'round',
				'line-cap': 'round'
			},
			'paint': {
				'line-color': colour,
				'line-width': 4,
			}
		}, 'tasks-slot');


		app.map.addSource('source-' + task.id, {
			'type': 'geojson',
			'data': {
				'type': 'FeatureCollection',
				'features': linesFeatures,
			}
		});


		app.imagesLoading++;
		app.map.loadImage('/images/icons/direction_w.png', function (error, image)  {
			if (!app.map.hasImage('direction-w')) {
				app.map.addImage('direction-w', image);
				app.imagesLoading--;
			}
		});
		app.imagesLoading++;
		app.map.loadImage('/images/icons/direction_d.png', function (error, image)  {
			if (!app.map.hasImage('direction-d')) {
				app.map.addImage('direction-d', image);
				app.imagesLoading--;
			}
		});
		
		let directionImage = 'direction-d';
		let textColour = '#666';
		let haloColour = '#FFF';
		if (app.isMapDark()) {
			directionImage = 'direction-w';
			textColour = '#FFF';
			haloColour = '#666';
		}

		app.map.addLayer({
			id: 'directions-' + task.id,
			type: 'symbol',
			source: 'source-' + task.id,
			paint: {
				'icon-opacity': .5
			},
			layout: {
				'symbol-placement': 'line',
				'icon-image': directionImage,
				'icon-rotate': 90,
				'icon-size': .5,
				'icon-rotation-alignment': 'map',
				'icon-allow-overlap': true,
				'icon-ignore-placement': true
			}
		}, 'tasks-slot');

		app.map.addLayer({
			'id': 'layer-' + task.id,
			'type': 'line',
			'source': 'source-' + task.id,
			'layout': {
				'line-join': 'round',
				'line-cap': 'round'
			},
			'paint': {
				'line-color': colour,
				'line-width': 4
			}
		}, 'tasks-slot');


		app.map.addLayer({
			"id": 'line-labels-' + task.id,
			"type": "symbol",
			"source": 'source-' + task.id,
			'paint': {
				'text-color': textColour,
				'text-halo-color': haloColour,
				'text-halo-width': 2,

			},
			"layout": {
				"symbol-placement": "line-center",
				"symbol-spacing": 10000,
				"symbol-avoid-edges": true,
				'text-allow-overlap': false,
				"text-font": ["Open Sans Regular"],
				"text-field": ['get', 'title'], // part 2 of this is how to do it
				"text-size": 14,
				'text-offset': [1.2, 1.2],
			}
		}, 'tasks-slot');

		//console.log('got here 6');

		//console.log('finished adding layers');
		

	}

	// function constructTaskLayers(mapObject) {
	// 	if (map==null) {
	// 		map = mapObject;
	// 	} 
	// }


	return {
		renderTask,
		redrawTasks,
		zoomToTasks,
		removeTaskFromMap,
		removeTasks,
		showTasks,
		firstLoad,
	}

}