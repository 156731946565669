import { ref, onMounted, onUnmounted, watch } from 'vue';

import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { useDrawTasks } from "@/Composables/UseDrawTasks.js";


const currentContest = ref(null);
const loadingContest = ref(0);
const loadingClasses = ref(0);
const classesList = ref([]); //  a list of task URLs by class
const tasksData = ref([]); // the actual list of tasks to display
const pilots = ref([]); // the list of contest pilots

const filterNonContestPilots = ref(false); // config option


/**
 * 
 * Soaring Spot contest manager
 * 
 */


export function useContestManager() {

	const app = appStateStore();
	const settings = settingsStore();
	const drawTasks = useDrawTasks();



	const setupWatcher = function() {
		watch(() => settings.contestID, (currentValue, oldValue) => {
			//console.log("contest id changed from " + oldValue + ' to ' + currentValue);

			// remove previous contest and tasks
			if (oldValue!==null) {
				drawTasks.removeTasks(tasksData.value);
				currentContest.value = null;
				tasksData.value.splice(0);
				classesList.value.splice(0);
			}

			initContest();
		});
	}


	// load the current contest ID
	const initContest = function() {
		//console.log("init contest " + settings.contestID);
		if (!settings.contestID) return;

		loadingContest.value++;

		window.axios.get('/api/soaringspot/contests/' + settings.contestID).then(function (response) {
			// console.log(response);

			loadingContest.value--;
			
			var data = response.data.data;
			//Object.assign(currentContest.value, data);
			currentContest.value = data;

			loadClasses();
			loadPilots(currentContest.value);
			filterNonContestPilots.value = true;

		}).catch(error => {
			console.log(error);
			loadingContest.value--;
		});
		// load the contest URL, then get the latest stuff
	}



	const clearContest = function() {
		// remove from map
		drawTasks.removeTasks(tasksData.value);
		filterNonContestPilots.value = false;

		settings.contestID = null;
		currentContest.value = null;
		tasksData.value.splice(0);
		classesList.value.splice(0);
		pilots.value.splice(0);
	}



	const setContest = function(contest) {
		// console.log("set contest");
		currentContest.value = contest;
		//loadClasses();
	}



	const loadClasses = function() {
		// console.log('loading tasks');
		loadingClasses.value++;
		loadingContest.value++;
		window.axios.get('/api/soaringspot/tasks?contest-url=' + currentContest.value.source_url).then(function (response) {
			loadingClasses.value--;
			loadingContest.value--;
			
			classesList.value = response.data.data;

			for (var i=0; i<classesList.value.length; i++) {
				classesList.value[i].show=true;
			}

			// console.log("classesList.value");
			// console.log(classesList.value);

			addLatestTasks();

		}).catch(error => {
			console.log(error.errors);
			loadingClasses.value--;
			loadingContest.value--;
		});
	}


	function addLatestTasks() {
		// loop through classes, and activate the latest task in each class
		// console.log("Adding latest tasks");
		// console.log(tasks);
		if (!classesList.value) return;

		// console.log('adding latest tasks');
		// console.log(classesList.value);

		// remove any existing tasks
		drawTasks.removeTasks(tasksData.value);
		tasksData.value.splice(0);

		for (var i=0; i<classesList.value.length; i++) {
			// find the latest task
			if (classesList.value[i].tasks) {
				
				// load the first task, which should be the latest
				// only activate if actually turned on
				if (classesList.value[i].show) {
					loadContestTask(classesList.value[i].tasks[0], classesList.value[i]);
				}

			}
		}
	}

	// optional class to keep track if it's loading or not
	function loadContestTask(task, clss=null)
	{
		// check we don't have this task already.
		// console.log("Looking for existing task");
		// console.log(task);
		// console.log(this.settings.tasks);

		// don't continue if we don't have a task URL
		if (!task.url) {
			return false;
		}

		for (var i=0; i<tasksData.value.length; i++) {
			if (tasksData.value[i].url == task.url) {
				// it already exists, just turn it on
				tasksData.value[i].show=true;
				// console.log('Found task, turning it on');
				this.zoomToTask(tasksData.value[i]);
				return;
			}
		}

		if (clss) clss.loadingTask = true;
		loadingContest.value++;

		// otherwise load it up
		window.axios.get('/api/soaringspot/load-task/?url=' + encodeURIComponent(task.url)).then(function (response) {
			loadingContest.value--;
			var data = response.data.data;

			// console.log("loaded task");
			// console.log(data);

			var task_name = task.name;
			if (data.task_name) {
				task_name = data.task_name;
			}

			var newTaskToPush = {
				'name':task_name,
				'url':task.url,
				'show':true,
				'task': data,
				'loading': false,
				'loaded': true
			};
			// console.log("task pushed:");
			// console.log(newTaskToPush);
			tasksData.value.push(newTaskToPush);
			// console.log(newTaskToPush);

			drawTasks.redrawTasks(tasksData.value);
			drawTasks.zoomToTask(newTaskToPush);

			//that.redrawTasks();
			//that.zoomToTask(newTaskToPush);

			// that.$emit('update:tasks', that.tasks);
			// that.$emit('redrawTasks');
			// that.$emit('zoom', task);

			if (clss) clss.loadingTask = false;
		}).catch(error => {
			loadingContest.value--;
			if (clss) clss.loadingTask = false;
			console.log(error.errors);
		});
	}

	function loadPilots(contest) {
		window.axios.get('/api/soaringspot/pilots?contest-url=' + contest.source_url).then(function (response) {
			pilots.value = response.data.data;
			console.log("Piltos Loaded for contest " + contest.source_url);
			// console.log(that.contestPilots);
		}).catch(error => {
			console.log(error.errors);
		});
	}

	function classPilots(className) {
		//console.log("Checking for classname " + className);
		return pilots.value.filter(obj => {
			return obj.class == className;
		});
	}

	function pilotDetails(contestRego) {
		if (!pilots.value) return null;
		var pilot = pilots.value.find(pilot => pilot.comp_id == contestRego);
		if (pilot) return pilot;
		return null;
	}

	function isClassActive(className) {
		for (var i=0; i<classesList.value.length; i++) {
			//console.log('comparing ' + classesList.value[i].name + ' to ' + className );
			if (classesList.value[i].name == className) {
				if (classesList.value[i].show) {
					//console.log('given class name of  ' + className);
					//console.log(classesList.value[i]);
					return true;
				}
			}
		}
		return false;
	}


	return {
		currentContest,
		setContest,
		loadingClasses,
		loadingContest,
		clearContest,
		initContest,
		classesList,
		tasksData,
		addLatestTasks,
		setupWatcher,
		pilots,
		filterNonContestPilots,
		classPilots,
		pilotDetails,
		isClassActive,
	};

}
