import { watch } from 'vue';

import { pointsStore } from "../stores/PointsStore.js";
import { settingsStore } from "../stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { useMapTools } from "@/Composables/MapTools.js";

//import { renderHeight } from "@/Helpers.js";
import { point, lineString } from '@turf/helpers';
import bearing from '@turf/bearing';
import destination from '@turf/destination';
import circle from '@turf/circle';
import sector from '@turf/sector';
import union from '@turf/union';

/**
 * 
 * Soaring Spot tasks
 * 
 */


export function useDrawTasks() {

	const points = pointsStore();
	const settings = settingsStore();
	const app = appStateStore();
	const mapTools = useMapTools();

	function redrawTasks(tasks) {

		// console.log("drawing tasks");
		// console.log(tasks);

		// default dark colours
		var colours = [
			'#093145',
			'#107896',
			'#829356',
			'#9A2617',
			'#C25714',
			'#BCA136',
			];
		var coloursLight = [
			'#3C6478',
			'#43ABC9',
			'#B5C689',
			'#EFD469',
			'#F58B4C',
			'#CD594A',
			];

		if (app.isMapDark()) {
			colours = coloursLight;
		}

		for (var i=0; i<tasks.length; i++) {

			// add the colour to the list of tasks
			//console.log(colours.length + ' ' + i + ' ' +  i % colours.length);
			
			tasks[i].colour = colours[i % colours.length];

			removeTaskFromMap(tasks[i]);
			if (tasks[i].show) renderTask(tasks[i]);

			tasks[i].coordinates = [];

			for (var j=0; j<tasks[i].task.task_points.length; j++)
			{
				tasks[i].coordinates.push([
					tasks[i].task.task_points[j].longitude,
					tasks[i].task.task_points[j].latitude,
				]);
			}
		}
	}

	function removeTasks(tasks) {
		if (!tasks) return;
		for (var i=0; i<tasks.length; i++) {
			removeTaskFromMap(tasks[i]);
		}
	}

	function removeTaskFromMap(task) {
		// console.log('removing task ' + task.url + ' from map');
		if (app.map.getLayer('layer-' + task.url)) {
			app.map.removeLayer('layer-' + task.url);
		}
		if (app.map.getSource('source-' + task.url)) {
			app.map.removeSource('source-' + task.url);
		}
		if (app.map.getLayer('tp-layer-' + task.url)) {
			app.map.removeLayer('tp-layer-' + task.url);
		}
		if (app.map.getLayer('tp-layer-fill-' + task.url)) {
			app.map.removeLayer('tp-layer-fill-' + task.url);
		}
		if (app.map.getSource('tp-source-' + task.url)) {
			app.map.removeSource('tp-source-' + task.url);
		}
	}


	function zoomToTask(task) {
		var coords = task.coordinates;

		var bounds = coords.reduce(function(bounds, coord) {
			return bounds.extend(coord);
		}, new app.mapbox.LngLatBounds(coords[0], coords[1]));

		app.map.fitBounds(bounds, {
			padding: 70
		});
	}

	function renderTask(task) {
		// console.log('rendering task');
		// check we haven't already loaded it
		if (app.map.getLayer('layer-' + task.url)) {
			return;
		}

		if (!task.task) return;
		if (!task.loaded) return;

		//console.log('got here 1');
		// console.log(task);

		//this.clearCurrentTask();
		var coordinates = [];

		for (var i=0; i<task.task.task_points.length; i++) {
			var p = task.task.task_points[i];
			// only create a line IF this is not a multi-start point
			if (p.multiple_start==false) {
				coordinates.push([p.longitude, p.latitude]);
			}
		}
		//console.log('got here 2');

		// create turnpoints
		var turnpoints = [];
		for (var i=0; i<task.task.task_points.length; i++) 
		{
			// console.log(task.task.task_points);
			var t = task.task.task_points[i];

			// var center = [-75.343, 39.984];
			// var radius = 5;
			var options = {steps: 40, units: 'meters'};
			
			// check if this is a line (or a start semi-circle which we will draw as a line)
			if (t.oz_line==true || (t.type=='start' && t.oz_type=='next')) {

		//console.log('got here 2.1');
				// calc bearing to the next point
				var centerPoint = point([t.longitude, t.latitude]);
		//console.log('got here 2.11');
				// check if this is a start or finish line
				if (t.type=='start') {
					// it's a start line. Use next point
					var point2 = point([task.task.task_points[i+1].longitude, task.task.task_points[i+1].latitude]);
				}

				if (t.type=='finish') {
					// must be the end point. Use previous point.
					var point2 = point([task.task.task_points[i-1].longitude, task.task.task_points[i-1].latitude]);
				}
		//console.log('got here 2.3');
				var brng = bearing(centerPoint, point2);

				// console.log('bearing to next/previous point is: ' + brng);

				// create end points of the line
				var distance = t.oz_radius1;
				var options = {steps: 60, units: 'meters'};

				// check if we're going to go over 180, if so use negative instead
				if (brng < 0) {
					var bearing1 = brng + 90;
					var bearing2 = brng + 270;
				} else {
					var bearing1 = brng - 90;
					var bearing2 = brng - 270;
				} 
		//console.log('got here 2.4');

				// console.log('bearing 1: ' + bearing1 + ' bearing2 ' + bearing2);

				// create a destination point for each end of the line
				var destination1 = destination(centerPoint, distance, bearing1, options);
				var destination2 = destination(centerPoint, distance, bearing2, options);

		//console.log('got here 2.5');
				// console.log('end of line point 1:');
				// console.log(destination1);
				// console.log('end of line point 2:');
				// console.log(destination2);

				turnpoints.push(lineString([destination1.geometry.coordinates, destination2.geometry.coordinates]));

		//console.log('got here 3');
			} else {

				// check if we are drawing a circle or a sector
				if (t.oz_angle1==180)
				{
					// draw circle
					turnpoints.push(circle([t.longitude, t.latitude], t.oz_radius1, options));
				} else {
					// it's a sector.

					var radius = t.oz_radius1;
					var centerPoint = point([t.longitude, t.latitude]);
					var options = {steps: 100, units: 'meters'};

					// do we have a fixed angle or not?
					switch (t.oz_type) {
						case 'fixed':
							// console.log("fixed")
							// figure out bearing to the two sides of the segment
							// oz_angle12 is between 0 and 360. We need bearing between -180 and 180
							var centerBearing = Math.round(t.oz_angle12 - 180);
							var bearing1 = t.oz_angle12 - t.oz_angle1 - 180; // one edge of segment
							var bearing2 = t.oz_angle12 + t.oz_angle1 - 180 + 0.5; // other edge of segment
							break;

						case 'symmetric':
							// console.log("symmetric")

							var nextPoint = point([task.task.task_points[i+1].longitude, task.task.task_points[i+1].latitude]);
							var previousPoint = point([task.task.task_points[i-1].longitude, task.task.task_points[i-1].latitude]);

							var bearingNextPoint = bearing(centerPoint, nextPoint);
							var bearingPreviousPoint = bearing(centerPoint, previousPoint);

							// console.log("oz_angle1: " + (t.oz_angle1));
							// console.log("oz_angle12: " + (t.oz_angle12));
							// console.log("bearingNextPoint: " + (bearingNextPoint));
							// console.log("bearingPreviousPoint: " + (bearingPreviousPoint));

							// work out the gap between, and the bearing between them
							var gapBetween = bearingNextPoint-bearingPreviousPoint;
							var bearingBetween = bearingNextPoint - gapBetween/2;

							if (gapBetween<180 && gapBetween>-180) bearingBetween = bearingBetween+180;

							// console.log("gapBetween: " + gapBetween);
							// console.log("bearingBetween: " + bearingBetween);

							// add on the size of the sector either side of it
							var bearing1 = bearingBetween - (t.oz_angle1); // one edge of segment
							var bearing2 = bearingBetween + (t.oz_angle1+1) ; // other edge of segment

							break;

						default:
							turnpoints.push(circle([t.longitude, t.latitude], t.oz_radius1, options));
							break;
					}

					// console.log('bearing1');
					// console.log(bearing1);
					// console.log('bearing2');
					// console.log(bearing2);


					//console.log('got here 4');
					var sect = sector(centerPoint, radius, bearing1, bearing2, options);

					// add the second circle sector if included
					if (t.oz_radius2>0) {
						var circle2 = circle([t.longitude, t.latitude], t.oz_radius2, options);
						var unn = union(sect, circle2);
						turnpoints.push(unn);
					} else {
						turnpoints.push(sect);
					}

					
				}

			}

		//console.log('got here 5');

// course_in: 228
// course_out: 267
// distance: 136865.24790278
// elevation: 137.4648
// latitude: -36.66278333333342
// longitude: 142.3733333333356
// multiple_start: false
// name: "09ControlPointEast"
// oz_angle1: 180
// oz_angle2: 0
// oz_angle12: 338
// oz_line: false
// oz_max_altitude: 0
// oz_move: false
// oz_radius1: 2000
// oz_radius2: 0
// oz_reduce: false
// oz_type: "symmetric"
// point_index: 3
// type: "point"
// Object Prototype

			// turnpoints.push({
			// 		'type': 'Feature',
			// 		'geometry': {
			// 			'type': 'Polygon',
			// 			'coordinates': circle
			// 		}
			// 	});
		}

		//console.log(turnpoints);


		var colour = '#000';
		if (task.colour) colour = task.colour;

		app.map.addSource('tp-source-' + task.url, {
			'type': 'geojson',
			'data': {
				'type': 'FeatureCollection',
				'features': turnpoints,
			}
		});
		app.map.addLayer({
			'id': 'tp-layer-' + task.url,
			'type': 'fill',
			'source': 'tp-source-' + task.url,
			'layout': {
			},
			'paint': {
				'fill-color': colour, // blue color fill
				'fill-opacity': 0.1
			}
		}, 'tasks-slot');
		app.map.addLayer({
			'id': 'tp-layer-fill-' + task.url,
			'type': 'line',
			'source': 'tp-source-' + task.url,
			'layout': {
				'line-join': 'round',
				'line-cap': 'round'
			},
			'paint': {
				'line-color': colour,
				'line-width': 4,
			}
		}, 'tasks-slot');


		app.map.addSource('source-' + task.url, {
			'type': 'geojson',
			'data': {
				'type': 'Feature',
				'properties': {},
				'geometry': {
					'type': 'LineString',
					'coordinates': coordinates
				}
			}
		});

		app.map.addLayer({
			'id': 'layer-' + task.url,
			'type': 'line',
			'source': 'source-' + task.url,
			'layout': {
				'line-join': 'round',
				'line-cap': 'round'
			},
			'paint': {
				'line-color': colour,
				'line-width': 4
			}
		}, 'tasks-slot');


		//console.log('got here 6');

		//console.log('finished adding layers');


	}

	// function constructTaskLayers(mapObject) {
	// 	if (map==null) {
	// 		map = mapObject;
	// 	} 
	// }


	return {
		renderTask,
		redrawTasks,
		zoomToTask,
		removeTaskFromMap,
		removeTasks,
	}

}