<script setup>
import { ref } from 'vue';
import PureMessage from '@/PureComponents/PureMessage.vue';

import { messagesStore } from "@/stores/MessagesStore.js";

const messages = messagesStore();
</script>

<template>
	<div class="pear-messages fixed max-w-ld w-full sm:w-1/2 md:w-1/3 xl:w-1/4 top-0 right-0 mt-2 z-50">
		<pure-message 
			v-for="message in messages.messages" 
			v-bind:key="message.id" 
			:msg="message"
			@expired="msg => messages.remove(msg)">
		</pure-message>
	</div>
</template>


