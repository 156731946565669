import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";


export const coloursStore = defineStore('colours', () => {

	const app = appStateStore();

	// original puretrack colours
	//const colours = ['B4503C', 'C86E3C', '6E3C8C', 'AA4646', 'BE643C', 'B4823C', '3C6E96', 'BE783C', 'A08C46', '968C46', '8C9646', '64A050', '3264A0', '505096', 'BE5A3C', 'A03C46', 'AA3C46', '789650', '6EA050', '5A965A', '5A9664', '508C6E', '508278', '467882', '3C5AA0', '5A4696', '64468C', '829646', 'B45A3C', 'C8783C', '468282', '3C788C', '325AA0', '465AA0', '4650A0', '5A5096', '644696', 'AA8246', '829650', 'AA823C', 'A03246', 'C8643C', 'AA8C46', '5AA05A', '508C64', '508C78', '468278', '46788C', '3C6E8C', '326496', '2864A0', '285AAA', '325AAA', '5050A0', '6E3C82', '783282', 'AA463C'];

	const getColour = function(key, pointColour=null) {

		// console.log("pointColour");
		// console.log(pointColour);
		if (pointColour!==null) return pointColour;

		// console.log(this.hashCode(key));
		// console.log(this.colours.length);
		// console.log(Math.round(this.hashCode(key)) % this.colours.length);
		return colours[ hashCode(key) % colours.length ];
	}

	// large array of colours
	// const colours = [
	// 	'786030', '603030', 'C0A848', '303048', '486048', 'C06030', 'A83030', '903048', '483078', '603060', '609078', '489090', '90A860', '789060', '907830', '784830', 'C07830', '606030', '483048', 'A84830', '483030', 'C09048', 'A8A848', '783048', '601830', '783060', '303078', '306048', '306060', 'C09030', 'C04830', '606048', '304860', '78A860', '303090', '604830', '783030', '489078', '789078', '90A848', 'A83048', '483060', '303060', '607848', '787830', '486060', '906030', '603048', '787848'
	// 	];



	// smaller array of spectrum
	const colours = ['C0A848', 'C06030', 'A83030', '903048', '483078', '603060', '609078', '489090', '789060', '90A860', 'C07830', 'A84830', 'C09048', 'A8A848', '783048', 'C09030', '783060', '303078', '78A860', 'C04830', '489078', '303090', '789078', '90A848', 'A83048', '483060'];

	//const colours = ['306090', '603060', '903048', 'C04830', 'A83030', '483078', 'D86030', '304890', '307890', '783048', '303090', '783060'];

	function hashCode(s) {
		if (typeof(s) == 'undefined') {
			return Math.abs(Math.random()*100);
		}
		//return Math.random(100);
		var hash = 0;
		if (s.length == 0) return hash;

		for (var i = 0; i < s.length; i++) {
			var char = s.charCodeAt(i);
			hash = ((hash << 6) - hash) + char;
			hash = hash & hash;
		}
		return Math.abs(hash);
		//return s=>{for(var i=0,h=9;i<s.length;)h=Math.imul(h^s.charCodeAt(i++),9**9);return h^h>>>9}
	}

	return { 
		colours,
		getColour
	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(coloursStore, import.meta.hot))
}

