import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

export const messagesStore = defineStore('messages', () => {

	const messages = ref([]);
	var counter = ref(0);
	var timer = 3000;

	function error(message, stay=false) {
		var msg={id:counter.value, message:message, type:'error', stay:stay}
		counter.value++;
		messages.value.unshift(msg);
		if (!stay) setTimeout(remove, timer, msg);
	}

	function success(message, stay=false) {
		var msg={id:counter.value, message:message, type:'success', stay:stay}
		counter.value++;
		messages.value.unshift(msg);
		if (!stay) setTimeout(remove, timer, msg);
	}

	function warning(message, stay=false) {
		var msg={id:counter.value, message:message, type:'warning', stay:stay}
		counter.value++;
		messages.value.unshift(msg);
		if (!stay) setTimeout(remove, timer, msg);
	}

	function info(message, stay=false) {
		var msg={id:counter.value, message:message, type:'info', stay:stay}
		counter.value++;
		messages.value.unshift(msg);
		if (!stay) setTimeout(remove, timer, msg);
	}

	function remove(message) {
		for (var i=0; i<messages.value.length; i++) {
			if (message.id == messages.value[i].id) {
				messages.value.splice(i, 1);
			}
		}
	}

	return { 
		messages,
		error,
		success,
		warning,
		info,
		remove
	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(messagesStore, import.meta.hot))
}