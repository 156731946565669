import { watch, ref, computed } from 'vue';

import { settingsStore } from "../stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";
import { tasksStore } from "@/stores/TasksStore.js";

import { pointShortName } from '../Helpers.js';
import { useI18n } from 'vue-i18n';

const loading = ref(0);
const group = ref(null); // the group if it's loaded
const groups = ref(null); // list of groups

//const groupMembers = ref([]); // array of the group members
const reloadGroup = ref(false); // whether to reload the group or not

const password = ref(null);
const temporaryPoint = ref(null); // used for the password dialogue box

const groupMembers = computed(() => {
	return group.value?.members
});

export function useGroups() {

	const {t} = useI18n({})

	const settings = settingsStore();
	const app = appStateStore();
	const messages = messagesStore();
	const tasks = tasksStore();

	watch(() => reloadGroup.value, (currentValue, oldValue) => {
		//console.log("reloadGroup Triggered");
		reloadGroup.value = false;
		loadGroup();
	});


	function isInGroup(key) {
		if (groupMembers.value===null) return false;
		if (!groupMembers.value?.length>0) return false;
		var result = groupMembers.value?.find(obj => {
			return obj.key === key
		})
		if (result) return true;
		return false;
	}


	function loadGroups() {

		loading.value++;

		axios.get('/api/groups').then(function(response) {

			loading.value--;
			groups.value = response.data.data;

		}).catch(function (error) {
			loading.value--;
			console.log('Loading Groups Error:');
			console.log(error);
		});

	}

	function closeGroup() {
		settings.followGroup = false;
		settings.filterGroup = false;
		group.value = null;
		app.groupSlug=null;
		app.restartLoop=true;
	}


	function loadGroup() {

		loading.value++;
		//console.log('loading group');

		axios.get('/api/groups/byslug/' + app.groupSlug).then(function(response) {

			app.groupLoaded=true;

			loading.value--;
			group.value = response.data.data;
			app.group = group.value;
			
			settings.pauseFollowing = false;

			// load tasks that will include group tasks
			tasks.loadTasks();

		}).catch(function (error) {
			console.log(error);
			loading.value--;

			if (error?.response?.data?.message) {
				messages.error(error?.response?.data?.message);
			} else {
				messages.error("Failed to load group");
			}

		});
	}


	function amGroupManager() {
		if (group.value.user_id==app?.user?.id) return true;
		if (app.user?.level==1) return true;
		return false;
	}

	// add the given key to the current group
	function addToGroup(point) {

		temporaryPoint.value = point; // store this for later if it's needed

		// console.log(group.value);
		let isGroupAdmin = false;
		if (app.user?.id == group.value.user_id) isGroupAdmin = true;


		// console.log(app.user?.id);
		// console.log(group.value.user_id);

		// check password given
		if (group.value?.protected && isGroupAdmin==false) {

			if (password.value==null || password.value=='') {
				app.show='group-password';
				messages.error(t('group-menu.password-required'));
				return;
			}
		}

		// success! Ready to try adding it.
		let newMember = {};
		newMember.key = point.key;
		newMember.group_id = group.value.id;
		newMember.password = password.value;

		point.groupLoading = true;

		// add instantly to the array
		group.value?.members.push(newMember);

		axios.post('/api/groups/' + group.value.id + '/add', newMember).then(function(response) {

			app.show=null;
			point.groupLoading = false;
			messages.success('Added ' + pointShortName(point, 20, settings.shortenAircraftRegistrations) + ' to Group ' + group.value.name);
			//reloadGroup.value=true; // trigger a reload of the group details

		}).catch(function (error) {

			removeFromGroupInstant(point);

			// if (error.response.status==500) {
			// 	messages.error(t('group-menu.wrong-permission-add'));
			// 	app.show='group-password';
			// }

			point.groupLoading = false;
			if (error?.response?.data?.message) {
				messages.error(error?.response?.data?.message);
			} else {
				messages.error("Failed to add item to group");
			}

			console.log('Adding to Group Error:');
			console.log(error);
		});
	}



	function toggleGroup(point) {
		if (!isInGroup(point.key)) {
			addToGroup(point);
		} else {
			removeFromGroup(point);
		}
	}

	function loadGroups() {

		loading.value++;

		axios.get('/api/groups').then(function(response) {

			loading.value--;
			groups.value = response.data.data;

		}).catch(function (error) {
			loading.value--;
			console.log('Loading Groups Error:');
			console.log(error);
		});
	}


	function removeFromGroup(point) {
		console.log('removing from ' + group.value?.id + ' item ' + point.key);

		point.groupLoading = true;

		axios.delete('/api/groups/' + group.value?.id + '/remove/' + point.key).then(function(response) {
			messages.success(t('group-menu.removed-from-group'));
			point.groupLoading = false;

		}).catch(function (error) {
			console.log(error);

			if (error.response.status==401) {
				messages.error(t('group-menu.wrong-permission-remove'));
			} else {
				messages.error(t('group-menu.failed'));
			}
			loadGroup();
			point.groupLoading = false;
		});

		removeFromGroupInstant(point);

	}


	function removeFromGroupInstant(point) {
		//console.log(group.value.members);

		// delete instantly from the array
		for (var i = group.value.members.length - 1; i >= 0; --i) {
			if (group.value.members[i].key == point.key) {
				group.value.members.splice(i,1);
			}
		}
	}


	function clickFollow() {
		if (settings.followGroup==true) {
			settings.pauseFollowing=false;
			goToSelected();
		} else {
			settings.pauseFollowing=true;
		}
	}

	return {
		group,
		groups,
		groupMembers,
		amGroupManager,
		loading,
		isInGroup,
		loadGroups,
		addToGroup,
		removeFromGroup,
		loadGroup,
		loadGroups,
		toggleGroup,
		password,
		temporaryPoint,
		closeGroup,
	};
}
