import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, computed, watch } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { computeHeight, computeSpeed } from '../Helpers.js';
import { messagesStore } from "@/stores/MessagesStore.js";

import { useGroups } from "@/Composables/UseGroups.js";

import { useI18n } from 'vue-i18n'
import { useDrawCompTasks } from "@/Composables/useDrawCompTasks.js";

export const tasksStore = defineStore('tasks', () => {

	const settings = settingsStore();
	const app = appStateStore();
	const messages = messagesStore();
	const groups = useGroups();
	const drawTasks = useDrawCompTasks();

	const loading = ref(0);
	const { t } = useI18n()

	const tasks = ref([]); // currently loaded comp tasks

	// keep track of the current active tasks between loads
	const activeTasksArray = ref([]); 

	// function inTasksList(id) {
	// 	if (app.tasksToLoad==null) return false;
	// 	return app.tasksToLoad.includes(id);
	// }

	function clearTasks() {
		// console.log('removing tasks');
		// console.log(tasks.value);
		drawTasks.removeTasks(tasks.value);
		tasks.value.splice(0);
		activeTasksArray.value.splice(0);
	}

	function addToActiveTasksArray(task) {
		if (!activeTasksArray.value.includes(task.id)) {
			activeTasksArray.value.push(task.id);
		}
	}


	function activeTasksCount() {
		var result = tasks.value.filter(task => {
			return task.show === true;
		});
		return result.length;
	}

	function activeTasks() {
		let results = tasks.value.filter(function (el) {
			return el.show === true;
		});
		return results;
	}

	function myTasks() {
		return tasks.value.filter(function (el) {
			return el.user_id == app.user.id
		});
	}

	function groupTasks() {
		return tasks.value.filter(function (el) {
			return el.currentGroup
		});
	}

	function activateTask(task) {
		for (var i=0; i<tasks.value.length; i++)
		{
			if (tasks.value[i].id==task.id) {
				tasks.value[i].show = true;

				addToActiveTasksArray(task);
				//drawTasks.zoomToTasks(task);
			}
		}
	}

	function deactivateTask(task) {
		for (var i=0; i<tasks.value.length; i++)
		{
			if (tasks.value[i].id==task.id) {
				tasks.value[i].show = false;

				if (activeTasksArray.value.includes(task.id)) {
					activeTasksArray.value.splice(activeTasksArray.value.indexOf(task.id), 1);
				}

				drawTasks.removeTaskFromMap(task);

			}
		}
	}


	function toggleTask(task) {
		for (var i=0; i<tasks.value.length; i++)
		{
			if (tasks.value[i].id==task.id) {
				if (tasks.value[i].show) deactivateTask(task);
				else activateTask(task);
			}
		}
	}


	function addTaskToGroup(task_id, groupSlug) {

		axios.post('/api/tasks/' + task_id + '/add-to-group', {'group_slug': groupSlug}).then(function(response) {
			messages.success('Added to group.');

			loadTasks();
			groups.loadGroup();

		}).catch(function (error) {
			messages.error('Task not added to group for some reason.');
			task.deleting = false;
		});
	}

	function removeTaskFromGroup(task_id, groupSlug) {

		axios.post('/api/tasks/' + task_id + '/remove-from-group', {'group_slug': groupSlug}).then(function(response) {
			messages.success('Removed from group.');

			loadTasks();
			groups.loadGroup();

		}).catch(function (error) {
			messages.error('Task not added to group for some reason.');
			task.deleting = false;
		});
	}


	function loadTasks()
	{
		loading.value++;

		let url = '/api/tasks/';
		if (app.groupSlug) url = url + '?group_slug=' + app.groupSlug;
		axios.get(url).then(function(response) {
			loading.value--;
			tasks.value = response.data.data;

			for (var i=0; i<tasks.value.length; i++)
			{
				//console.log(tasks.value[i]);
				//tasks.value[i].group[]
				tasks.value[i].currentGroup = false;
				tasks.value[i].show = false;

				if (activeTasksArray.value.includes(tasks.value[i].id)) {
					activateTask(tasks.value[i]);
				}

				if (tasks.value[i].groups) {

					for (var j=0; j<tasks.value[i].groups.length; j++) {
						if (tasks.value[i].groups[j].slug==app.groupSlug) {
							tasks.value[i].currentGroup = true;
							activateTask(tasks.value[i]);
							// tasks.value[i].show = true;
							// activeTasksArray.value.push(tasks.value[i].id);
						}
					}

				}
			}

			// zoom to all tasks
			let test = [{
				coordinates: [[1,1],[2,2]],
				show: true,
			}];


		}).catch(function (error) {
			console.log(error);
			loading.value--;
		});
	}


	function deleteTask(task) {

		task.deleting = true;
		axios.delete('/api/tasks/' + task.id).then(function(response) {

			deactivateTask(task);

			task.deleting = false;
			messages.success( t('tasks.task-deleted') );

			loadTasks();

		}).catch(function (error) {
			messages.error('Not deleted for some reason!');
			task.deleting = false;
		});
	}


	return {
		tasks,
		loadTasks,
		deleteTask,
		myTasks,
		groupTasks,
		loading,
		activateTask,
		deactivateTask,
		toggleTask,
		activeTasksCount,
		addTaskToGroup,
		removeTaskFromGroup,
		activeTasks,
		activeTasksArray,
		addToActiveTasksArray,
		clearTasks,
	}

});


// make sure to pass the right store definition, `tasksStore` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(tasksStore, import.meta.hot))
}