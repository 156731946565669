<script setup>

const props = defineProps(['msg'])

const emit = defineEmits(['expired'])

function remove() {
	emit('expired', props.msg);
}

function getClassType() {
	switch(props.msg.type)
	{
		case 'success': return 'bg-green-700'; break;
		case 'error': 
		case 'danger':
			return 'bg-red-800'; break;
		case 'warning': return 'bg-yellow-600'; break;
		case 'info': return 'bg-blue-600'; break;
	}
	return 'bg-gray-300';
}
</script>


<template>
	
	<div class="pear-message shadow-md mb-2 rounded p-2 text-white mr-2 ml-2 z-50"
		:class="getClassType()">
		<button v-on:click="remove()" class="block pl-2 pb-1 float-right font-semibold text-xs uppercase">
			<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>
		</button>
		{{props.msg.message}}
	</div>
</template>